import { Injectable } from '@angular/core';


// Interface
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AppService } from 'app/services/app.service';

@Injectable()
export class ReportsProvider {
  constructor(public http: HttpClient, 
    private appService: AppService) { }


  public get(page: number = 1): Observable<any> { //TODO: Safe Query
    const limit: number = 15;
    return this.http.get(`${environment.ENDPOINT}${environment.SERVICES.REPORT}/?page=${page}&limit=${limit}&suscription=${this.appService.getViewSuscription()}`);
  }

  public download(id: string): Observable<any> {
    const limit: number = 15;
    return this.http.get(`${environment.ENDPOINT}${environment.SERVICES.REPORT_PDF}/${id}`);
  } 
}
